import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';


const Intro = () => {

    const navigate = useNavigate();
    
    return (
        <div className="main2">
            <div className="vid_container">
                <div className="vid">
                    <iframe width="100%" height="auto" src="https://www.youtube.com/embed/wZ9ZOB5SBqo?si=IoADzRl5v7OUJ4Ss" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </div>
        </div>

    )

}

export default Intro;