import React from 'react';
import {Routes, Route } from 'react-router-dom';

import { DataProvider } from './firebase/datacontext.js';

import './elementos/styles.css';
import './elementos/animate.css';

import Nav from './elementos/nav.js';
import Top from './elementos/top.js';
import Intro from './elementos/intro.js';
import Feed from './elementos/feed.js';
import Answer from './elementos/answer.js';
import Create from './elementos/create.js';

function App() {
  return (
    <DataProvider>
      <Top/>
      <Nav/>
      <Routes>
        <Route path="/" element={<Intro/>}/>
        <Route path="/Feed" element={<Feed/>}/>
        <Route path="/Answer" element={<Answer/>}/>
        <Route path="/Create" element={<Create/>}/>
      </Routes>
    </DataProvider>
  );
}

export default App;
