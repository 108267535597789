import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import like_icon from '../images/like_icon.png';
import dislike_icon from '../images/dislike_icon.png';
import sad_icon from '../images/sad_icon.png';


const Answer = () => {

    const navigate = useNavigate();

    //
    const { getFeed, feed, saveLikes,saveDislikes, saveSads, saveResponse, nextQuestions, lastreg, getSearchFeed, deleteQuestion } = useContext(DataContext);

    //STATES
    const [response, setResponse] = useState('');
    const [approved, setApproved] = useState('');

    //NEXT PAGE
    async function nextP(){
        if(lastreg == undefined){
            console.log("Init")
        }else{
            await nextQuestions();
        }  
    }

    //DRAW LIKES
    function setLikes(a, b){
        var x = document.getElementById('l' + a);
        x.innerHTML = b;
        x.classList.add('bounceIn')
        saveLikes(a, b);
    }

     //DRAW DISLIKES
     function setDislikes(a, b){
        var x = document.getElementById('d' + a);
        x.innerHTML = b;
        x.classList.add('bounceIn')
        saveDislikes(a, b);
    }

    //DRAW SADS
    function setSads(a, b){
        var x = document.getElementById('s' + a);
        x.innerHTML = b;
        x.classList.add('bounceIn')
        saveSads(a, b);
    }

    //SAVE RESPONSE AND STATUS
    function saveRes(a, b, c, d, e){
        var x = document.getElementById('msg' + a);   
        x.style.display = "block";

        if(approved == '' && response == ''){
            saveResponse(a, d, e);
        }
        else if(approved == '' && response != ''){
            saveResponse(a, d, c);
        }
        else if(approved != '' && response == ''){
            saveResponse(a, b, e);
        }
        else{
            saveResponse(a, b, c);
        }
    }

    //SEARCH
    async function searching(){
        var a = document.getElementById("search").value.toLowerCase();
        var b = a.split(' ');
        var c = document.getElementById('loading_btn');

        await getSearchFeed(b);
        c.style.display = "none";
    }

    //DELETE QUESTION
    async function quitQuestion(a){
        await deleteQuestion(a);
        document.getElementById(a).style.display = "none";
    }

    //USE EFFECT
    useEffect(() => {
       getFeed();
     },[]);
    
    return (
        <div className="main">
            
            <h1>Administrador</h1>
            <button className="debug_btn" onClick={getFeed}>CHECK</button>
            <div className="main_boxes">
                <div className="search_box">
                    <input type="text" id="search" name="search" placeholder="Busca por correo, categoría o nombre"/>
                    <button className="search_btn" onClick={searching}>Buscar</button>
                    <div className="clear"></div>
                </div>

                {
                    feed.length == 0
                    ?
                    <p className="nodata_msg">No hay publicaciones.</p>
                    :
                    feed.map((item, index) => (
                        <div className="question_box" key={index} id={item.id}>

                            <div className="question_txt">
                                <p className="question_st">{item.category}</p>
                                <p className="question_mt">{item.question}</p>
                                <p className="question_at">{item.name} | {item.email}</p>
                            </div>
                            
                            <div className="question_likes">
                                <div className="question_btn" onClick={() => setLikes(item.id, item.likes + 1)}>
                                    <img src={like_icon} alt=""/>
                                    <p id={"l" + item.id} className="animated">{item.likes}</p>
                                </div>
                                <div className="question_btn" onClick={() => setDislikes(item.id, item.dislikes + 1)}>
                                    <img src={dislike_icon} alt=""/>
                                    <p id={"d" + item.id} className="animated">{item.dislikes}</p>
                                </div>
                                <div className="question_btn" onClick={() => setSads(item.id, item.sad + 1)}>
                                    <img src={sad_icon} alt=""/>
                                    <p id={"s" + item.id} className="animated">{item.sad}</p>
                                </div>

                                <div className="question_ok">
                                    <p className="question_stat">Status:</p>
                                    <select id="approved" name="approved" defaultValue={item.approved} onChange={e => setApproved(e.target.value)}>
                                        <option value="0">No aprobado</option>
                                        <option value="1">Aprobado</option>
                                    </select>
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="question_res">
                                <p className="res_tt">Respuesta:</p> 
                                <textarea id="response" name="response" maxLength="500" placeholder={item.response ? item.response : "La pregunta aún no ha sido respondida."} defaultValue={item.response} onChange={e => setResponse(e.target.value)}></textarea> 
                                <div className="edit_btns">
                                    <button className="inside_btn" onClick={() => saveRes(item.id, approved, response, item.approved, item.response)}>GUARDAR CAMBIOS</button>
                                    <button className="delete_btn" onClick={() => quitQuestion(item.id)}>ELIMINAR</button> 
                                </div>
                                <p id={'msg' + item.id} className="msg_ready">Respuesta y status guardados</p>
                            </div>
 
                        </div>
                ))}
                <button className="load_btn" id="loading_btn" onClick={nextP}>CARGAR MÁS</button>
            </div>
            
        </div>

    )


}

export default Answer;