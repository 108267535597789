import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {db, auth, storage} from './firebaseConfig';
import { doc, addDoc, setDoc, collection, getDocs, getDoc, updateDoc, deleteDoc, arrayUnion, arrayRemove, query, onSnapshot, orderBy, limit, where, deleteField, startAfter } from "firebase/firestore";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail, signInAnonymously } from "firebase/auth";

export const DataContext = createContext();

export const DataProvider = ({children}) => {

    //USE NAVIGATE
    const navigate = useNavigate();
    
    //REFERENCE COLLECTIONS
    const refquestions = collection(db, "questions");

    //STATES
    const [feed, setFeed] = useState([]);
    const [filterfeed, setFilterfeed] = useState([]);
    const [lastreg, setLastreg] = useState(null);
    const [lastfilterreg, setLastfilterreg] = useState(null);
    const [reloader, setReloader] = useState(1);
    const [checkMsgs, setCheckmsgs] = useState(false);
    
    
    //
    function checkConection(){
        console.log()
    }
    
    //LOGIN WITH GOOGLE
    async function logGoogle() {
        const GoogleProvider = new GoogleAuthProvider();
        try {
            const user = await signInWithPopup(auth, GoogleProvider);
            console.log(user)
        } catch (error) {
            console.log(error)
        }
    }

    //LOGIN ANONYMOUS
    async function logAnon(){
        try {
            await signInAnonymously(auth);
            console.log(auth.currentUser.uid);
        } catch (error) {
            console.log(error);
        }
    }

    //USE EFFECT
    useEffect(() => {
        logAnon();
     },[]);
    
    //GET FEED
    const getFeed = async() => {
        const q = query(collection(db, "questions"), orderBy('mod_update', 'desc'), limit(5));
        //const q = query(collection(db, "questions"));
        const data = await getDocs(q);
        console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setFeed(data2);
        console.log(feed);
        setLastreg(data2[data2.length - 1]);
    }

    //GET FEED
    const getSearchFeed = async(a) => {
        const q = query(collection(db, "questions"), where('tags', 'array-contains-any', a));
        //const q = query(collection(db, "questions"));
        const data = await getDocs(q);
        console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setFeed(data2);
        console.log(feed);
    }

    //GET FILTERFEED
    const getFilterfeed = async() => {
        const q = query(collection(db, "questions"), where( 'approved', '==', '1'), orderBy('mod_update', 'desc'), limit(5));
        //const q = query(collection(db, "questions"));
        const data = await getDocs(q);
        console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setFilterfeed(data2);
        console.log(feed);
        setLastfilterreg(data2[data2.length - 1]);
    }

    //NEXT FEED
    const nextQuestions = async() => {
        const q = query(collection(db, 'questions'), orderBy('mod_update', 'desc'), limit(5), startAfter(lastreg.mod_update));
        const data =  await getDocs(q);
        const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        setFeed(prevData => prevData.concat(data2));
        setLastreg(data2[data2.length - 1]);
    }

    //NEXT FEED
    const nextFilterquestions = async() => {
        const q = query(collection(db, 'questions'), where( 'approved', '==', '1'), orderBy('mod_update', 'desc'), limit(5), startAfter(lastfilterreg.mod_update));
        const data =  await getDocs(q);
        const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        setFilterfeed(prevData => prevData.concat(data2));
        setLastfilterreg(data2[data2.length - 1]);
    };


    //SAVE QUESTION
    async function saveQuestion(a, b, c, d, e){
        var x = new Date();
        await addDoc(collection(db, "questions"), {
            name: a,
            email: b,
            category: c,
            question: d,
            likes: 0,
            dislikes: 0,
            sad: 0,
            approved: '0',
            tags: e,
            mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
        });
    }

    //DELETE QUESTION
    async function deleteQuestion(a){
        try {
            await deleteDoc(doc(db, "questions", a));
        } catch (error) {
            console.log(error);
        }
    }

    //SAVE RESPONSE
    async function saveResponse(a, b, c){
        var x = new Date();
        await updateDoc(doc(refquestions, a), {
            approved: b,
            response: c,
            mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
        });
    }

    //SAVE LIKES
    async function saveLikes(a, b){
        await updateDoc(doc(refquestions, a), {
            likes: b
        });
    }

    //SAVE DISLIKES
    async function saveDislikes(a, b){
        await updateDoc(doc(refquestions, a), {
            dislikes: b
        });
    }

    //SAVE SADS
    async function saveSads(a, b){
        await updateDoc(doc(refquestions, a), {
            sad: b
        });
    }


    return(
        <DataContext.Provider 
            value={{  getFeed, feed, getFilterfeed, filterfeed, saveQuestion, saveLikes, saveDislikes, saveSads, saveResponse, nextQuestions, nextFilterquestions, lastreg, lastfilterreg, reloader, logAnon, getSearchFeed, deleteQuestion }}>
            
            {children}
        </DataContext.Provider>
    )
}