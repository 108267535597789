import React from 'react';
import { NavLink } from 'react-router-dom';

import icon1 from '../images/icon_home.png';
import icon2 from '../images/icon_feed.png';
import icon3 from '../images/icon_send.png';
import icon4 from '../images/icon_admin.png';
import flama from '../images/flama.png';

const Nav = () => {
    
    return (
        <div className="nav_bar">
            <NavLink className="nav_link" activeclassname="active" to="/">
                <img src={icon1} alt=""/>
                <p>Home</p>
            </NavLink>
            <NavLink className="nav_link" activeclassname="active" to="/Feed">
                <img src={icon2} alt=""/>
                <p>Feed</p>
            </NavLink>
            <NavLink className="nav_link" activeclassname="active" to="/Create">
                <img src={icon3} alt=""/>
                <p>Mensaje</p>
            </NavLink>
            <NavLink className="nav_link" activeclassname="active" to="/Answer">
                <img src={icon4} alt=""/>
                <p>Admin</p>
            </NavLink>

            <div className="decorative">
                <img src={flama} alt=""/>
            </div>
        </div>
    )
}

export default Nav;